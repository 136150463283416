import React, {useEffect} from 'react';
const pdf = "/jidelnicek.pdf";

const JidelnicekPage = () => {
    useEffect(() => {
        window.open(pdf);
    });

    return <>Jidelnicek</>
};

export default JidelnicekPage